.about {
  padding-top: 70px;
  padding-bottom: 20px;

  @include vp--1023 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.about__container {
  display: flex;
  align-items: flex-start;

  @include vp--767 {
    flex-direction: column;
  }
}

.about__title {
  margin: 0 0 65px;

  @include vp--1279 {
    margin-bottom: 45px;
  }

  @include vp--767 {
    margin-bottom: 25px;
  }
}

.about__canvas-wrap {
  width: 48%;
  height: 635px;
  margin-right: 35px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: -10px;
    right: -10px;
    bottom: 0;
    background: linear-gradient(180deg, #F5F7FA 11.74%, rgba(245, 247, 250, 0) 33.1%, rgba(245, 247, 250, 0) 73.72%, #F5F7FA 90.91%);
  }

  @include vp--1279 {
    width: 40%;
    height: 500px;
  }

  @include vp--767 {
    width: 40%;
    height: 300px;
    margin: 0 auto 30px;
  }
}

.about__canvas {
  width: 100%;
  height: 100%;
}

.about__text {
  width: calc(52% - 35px);

  .markered-list__items {
    max-width: 770px;
  }

  @include vp--1279 {
    width: calc(60% - 35px);
  }

  @include vp--767 {
    width: 100%;
  }
}

.about__list {
  margin-bottom: 70px;

  @include vp--1279 {
    margin-bottom: 45px;
  }

  @include vp--767 {
    margin-bottom: 25px;
  }
}

.about__desc {
  margin: 0;
  color: $color-blue-dark;
}
