.request {
  padding-top: 80px;
  padding-bottom: 80px;

  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-100%, -50%);
    width: 1270px;
    height: 1276px;
    background: url("../img/background/request-bg.svg") no-repeat;
    background-size: cover;
    pointer-events: none;
  }

  @include vp--1023 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.request__container {
  display: flex;
  align-items: center;

  @include vp--767 {
    display: block;
  }
}

.request__text {
  max-width: 355px;
  min-height: 800px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;

  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 800px;
    height: 800px;
    right: -160px;
    top: 50%;
    transform: translateY(-50%);
    background: url("../img/background/request-circle.svg") no-repeat;
    background-size: 100% 100%;
  }

  @include vp--1279 {
    &::before {
      right: -80px;
    }
  }

  @include vp--1023 {
    min-height: 600px;

    &::before {
      width: 500px;
      height: 500px;
      right: -10px;
    }
  }

  @include vp--767 {
    min-height: 350px;
    text-align: center;
    margin: 0 auto 30px;
    max-width: 320px;

    &::before {
      width: 350px;
      height: 350px;
      right: auto;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }


  &.sal-animate::before {
    animation: rotate 1s forwards;

    @include vp--767 {
      animation-name: rotateMobile;
    }
  }
}

.request__title {
  line-height: 1.3;
  margin: 0 0 20px;
}

.request__desc {
  max-width: 300px;

  p {
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include vp--767 {
    margin: 0 auto;
  }
}

.request__form {
  width: 50%;
  max-width: 570px;

  @include vp--767 {
    width: auto;
    max-width: 100%;
  }
}

.request__field {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  @include vp--1279 {
    margin-bottom: 20px;
  }
}

.request__field-title {
  color: rgba($color-almost-black, 0.4);

  font-size: 18px;
  line-height: 1;
  margin: 0 0 20px;

  @include vp--1279 {
    font-size: 16px;
  }

  @include vp--1023 {
    font-size: 14px;
  }

  @include vp--767 {
    font-size: 12px;

    margin-bottom: 10px;
  }
}

.request__radios-group {
  display: flex;

  .field-radio {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }

    @include vp--1279 {
      margin-right: 20px;
    }

    @include vp--767 {
      margin-right: 10px;
    }
  }
}

.request__fields {
  margin-bottom: 50px;

  @include vp--1279 {
    margin-bottom: 35px;
  }
}

.request__personal-data {
  margin-bottom: 40px;

  @include vp--1279 {
    margin-bottom: 20px;
  }
}
