.faq {
  padding-top: 80px;
  padding-bottom: 160px;

  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2216px;
    height: 1169px;
    background: url("../img/background/faq-bg.svg") no-repeat;
    background-size: cover;
    pointer-events: none;
  }

  @include vp--1023 {
    padding-top: 40px;
    padding-bottom: 80px;
  }
}

.faq__container {
  max-width: 1030px;
}

.faq__title {
  margin: 0 0 70px;
  text-align: center;
  line-height: 1;

  @include vp--1023 {
    margin-bottom: 30px;
  }
}
