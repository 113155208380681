.visualization {
  padding-top: 105px;
  padding-bottom: 80px;

  position: relative;
  z-index: 1;

  @include vp--1023 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2323px;
    height: 1617px;
    background: url("../img/background/viz-bg.png") no-repeat;
    background-size: cover;
    pointer-events: none;
  }
}

.visualization__title {
  margin: 0 0 145px;
  text-align: center;
}

.visualization__list {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  z-index: 1;
}

.visualization__item {
  text-align: center;
}

.visualization__content {
  display: flex;
  align-items: center;

  @include vp--1023 {
    flex-direction: column;
  }
}

.visualization__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 66px;
  border-radius: 8px;
  border: 2px solid transparent;
  background: linear-gradient($color-almost-white, $color-almost-white),
              linear-gradient(210deg, #AFCBFF, #ECD9FF);
  background-origin: border-box;
  background-clip: content-box, border-box;

  svg {
    display: block;
    max-height: 90%;
  }

  @include vp--1023 {
    width: 78px;
    height: 58px;
  }

  &--small {
    width: 50px;
    height: 50px;

    @include vp--1023 {
      width: 46px;
      height: 46px;
    }
  }
}

.visualization__item {
  transition-delay: 1.6s;

  width: 88px;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;

  &:first-child,
  &:last-child {
    width: 170px;
    margin-left: auto;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(2) {
    transition-delay: 1.75s;
  }

  &:nth-child(3) {
    transition-delay: 1.9s;
  }

  &:nth-child(4) {
    transition-delay: 2.05s;
  }

  @include vp--1023 {
    margin-bottom: 0;
  }
}

.visualization__item-title {
  margin: 0 0 11px;

  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  color: $color-almost-black;

  @include vp--1023 {
    font-size: 14px;
    line-height: 15px;
  }
}

.visualization__left {
  width: 31.5%;
  position: relative;

  @include vp--1023 {
    width: auto;
  }
}

.visualization__list--left {
  width: 100%;
  padding-right: calc(100% - 190px);
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 211px;
    background: url("../img/svg/vis-lines-1.svg") no-repeat;
    background-size: 100% 100%;
    right: 10px;
    top: 86px;
    bottom: 30px;
  }

  &::after {
    width: 276px;
    height: 139px;
    background-image: url("../img/svg/vis-lines-2.svg");
    top: 50%;
    transform: translateY(-45%);
    bottom: auto;
  }

  @include vp--1023 {
    width: 265px;
    padding-right: 0;

    display: grid;
    grid-template-columns: 130px 130px;
    gap: 18px 4px;

    overflow: visible;
    margin-left: 25px;
    padding-bottom: 50px;

    margin-bottom: 35px;

    &::before,
    &::after {
      width: 244px;
      height: 154px;
      background-image: url("../img/svg/vis-lines-1-mobile.svg");
      right: auto;
      left: 0;
      bottom: 0;
      top: auto;
    }

    &::after {
      width: 126px;
      height: 50px;
      background-image: url("../img/svg/vis-lines-2-mobile.svg");
      left: 60px;
      transform: none;
    }

    .visualization__item {
      margin-left: 5px;

      &:last-child {
        order: -1;
        width: 74px;
        margin-left: 5px;
      }

      &:first-child {
        width: 130px;
      }

      &:nth-child(2) {
        justify-self: center;
        margin-right: 15px;
      }
    }
  }
}

.visualization__middle {
  width: 31.6%;
  padding-bottom: 31.6%;
  margin: 0 30px;

  position: relative;
  z-index: 2;

  @include vp--1023 {
    width: 290px;
    padding-bottom: 0;
    margin: 0 0 95px;
  }
}

.visualization__logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient($color-almost-white, $color-almost-white),
              linear-gradient(225deg, rgba(225, 217, 255, 0.2) 23.45%, rgba(225, 217, 255, 0.7) 37.85%, rgba(182, 205, 255, 0.7) 61.39%, rgba(182, 205, 255, 0.2) 74.66%);

  padding: 24%;
  background-clip: content-box, border-box;

  background-size: 100% 100%, 300% 300%;
  background-position: 0% 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  animation: logo 7s infinite 0.6s;

  img {
    margin: 0 10px;
  }

  @include vp--1023 {
    position: relative;
    width: 290px;
    height: 290px;

    padding: 66px;
  }
}

.visualization__list--middle {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 20px;
    height: 2px;
    background: linear-gradient(to right, #CBDEFF, transparent);

    top: 50%;
    transform: translateY(-50%);
    left: -20px;
  }

  &::after {
    left: auto;
    right: -20px;
    background: linear-gradient(to left, #CBDEFF, transparent);
  }

  .visualization__item {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    margin-bottom: 0;
    left: -66px;
    width: auto;

    &:nth-child(2) {
      left: 50%;
      transform: translateX(-50%);
      top: calc(50% + 70px);
    }

    &:last-child {
      left: auto;
      right: -66px;
    }
  }

  @include vp--1023 {
    &::before,
    &::after {
      width: 2px;
      height: 15px;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(to bottom, #CBDEFF, transparent);
    }

    &::after {
      top: auto;
      bottom: -15px;
      background: linear-gradient(to top, #CBDEFF, transparent);
    }

    .visualization__item {
      top: -65px;
      left: 50%;
      transform: translateX(-50%);
      top: -61px;

      &:last-child {
        top: auto;
        bottom: -61px;
        right: auto;
        left: 50%;
      }

      &:first-child {
        svg {
          transform: rotate(90deg)
        }
      }
    }
  }
}

.visualization__list--right {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  position: static;

  .visualization__item {
    width: auto;
    margin-left: auto;

    &:first-child,
    &:last-child {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    &:last-child {
      width: 150px;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: calc(100% - 180px);

    background: url("../img/svg/vis-lines-3.svg") no-repeat;
    background-size: 100% 100%;
    left: 10px;
    top: 85px;
    bottom: 30px;
  }

  &::after {
    width: 53px;
    background-image: url("../img/svg/vis-lines-4.svg");
    left: auto;
    right: 65px;
    top: 120px;
    bottom: 65px;
  }

  @include vp--1023 {
    flex-direction: row;
    flex-wrap: wrap;

    .visualization__item {
      &:nth-child(n) {
        width: 50%;
        margin: 0 0 20px;
      }

      &:last-child {
        order: -1;
        margin-right: auto;
        width: 116px;
      }

      &:first-child {
        width: 105px;
      }

      &:nth-child(2) {
        width: 100%;
      }
    }

    &::before {
      width: 178px;
      height: 118px;

      background-image: url("../img/svg/vis-lines-3-mobile.svg");

      top: -60px;
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      width: 176px;
      height: 67px;

      background-image: url("../img/svg/vis-lines-4-mobile.svg");

      bottom: 65px;
      left: 50%;
      transform: translateX(-50%);
      top: auto;
    }
  }
}

.visualization__lock {
  position: absolute;
  z-index: 1;
  width: 14px;
  height: 14px;
  background: linear-gradient(225deg, #B1CCFF 0%, #EBD9FF 100%);
  border-radius: 2px;
  right: 4px;
  bottom: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    width: 8px;
    height: 10px;
  }
}

.visualization__right {
  position: relative;
  z-index: 1;
  align-self: stretch;

  width: calc(36.9% - 60px);
  padding-left: calc(36.9% - 300px);

  padding-top: 20px;

  @include vp--1023 {
    width: 290px;
    margin: 0 auto;
    padding-left: 0;
  }
}

.visualization__vertical-logo {
  position: absolute;
  z-index: -1;
  left: 55%;
  bottom: 70px;
  width: 14px;
  height: 292px;

  @include vp--1023 {
    transform: translateX(-50%) rotate(-90deg);
    left: 50%;
    width: 11px;
    height: 228px;
    top: -130px;
  }
}

.visualization__list-title {
  font-size: 30px;
  line-height: 1;
  font-weight: 500;
  margin: 0;
  text-align: center;
  position: absolute;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: calc(100% + 15px);
    left: 50%;
    transform: translateX(-51%);
    width: 3px;
    height: 56px;
    background: url("../img/svg/vis-title.svg") no-repeat;
    background-size: 100%;
  }

  .visualization__left & {
    top: -125px;
    left: 17px;

    @include vp--1023 {
      left: 50%;
      transform: translateX(-45%);
      top: -60px;

      &::after {
        top: calc(100% + 5px);
        height: 20px;
      }
    }
  }

  .visualization__right & {
    top: -125px;
    right: 50px;

    @include vp--1023 {
      left: 50%;
      transform: translateX(-50%);
      right: auto;
      top: auto;
      bottom: -40px;

      &::after {
        top: auto;
        bottom: calc(100% + 3px);
        height: 20px;
      }
    }
  }
}
