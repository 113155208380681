.clients {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2217px;
    height: 1122px;
    background: url("../img/background/clients-bg.svg") no-repeat;
    background-size: cover;
    pointer-events: none;
  }

  @include vp--1023 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.clients__title {
  max-width: 570px;
  margin: 0 0 60px;

  @include vp--1023 {
    margin-bottom: 30px;
  }
}

.clients__list {
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }

  @include vp--1023 {
    margin-bottom: 30px;
  }
}
