// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "Rouble";

//   font-display: swap;
//   src:
//     url("../fonts/rouble.woff2") format("woff2"),
//     url("../fonts/rouble.woff") format("woff");
// }


@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/manrope-400.woff2') format('woff2'),
       url('../fonts/manrope-400.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/manrope-600.woff2') format('woff2'),
       url('../fonts/manrope-600.woff') format('woff');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/manrope-500.woff2') format('woff2'),
       url('../fonts/manrope-500.woff') format('woff');
}
/* manrope-700 - latin_cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/manrope-700.woff2') format('woff2'),
       url('../fonts/manrope-700.woff') format('woff');
}
