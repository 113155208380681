.field-radio__label {
  display: block;
  position: relative;
  padding-left: 39px;

  font-size: 22px;
  line-height: 24px;

  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 24px;
    height: 24px;
    border: 1px solid $color-blue-dark;
    border-radius: 50%;
    left: 0;
    top: 0;
    padding: 4px;
    background: $color-transparent;
    background-clip: content-box;
    transition: background-color $trans--300;
  }

  input:focus-visible + & {
    &::before {
      border-width: 2px;
    }
  }

  input:checked + & {
    &::before {
      background-color: $color-blue-dark;
    }
  }

  @include vp--1279 {
    font-size: 18px;
    line-height: 20px;
  }

  @include vp--1023 {
    font-size: 16px;
    line-height: 18px;
    padding-left: 28px;

    &::before {
      width: 18px;
      height: 18px;
      padding: 3px;
    }
  }

  @include vp--767 {
    font-size: 13px;
  }
}
