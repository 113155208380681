.contacts {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%), rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  border-radius: 40px;
  border: 4px solid $color-default-white;
  padding: 126px 81px 111px;
  max-width: 1340px;

  display: flex;
  align-items: flex-start;

  @include vp--1279 {
    padding: 61px 30px;
  }

  @include vp--1023 {
    display: block;
  }

  @include vp--767 {
    padding: 36px 15px;
  }
}

.contacts__title {
  margin: 0;

  line-height: 1;
  position: relative;
  margin-right: auto;
  padding-right: 17%;

  @include vp--1279 {
    padding-right: 14%;
  }

  @include vp--1023 {
    margin-bottom: 60px;
    padding-right: 0;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 145px;
    height: 30px;
    background: url("../img/svg/arrow-with-dots.svg") no-repeat;
    background-size: 368px 30px;
    background-position: right center;
    right: 0;
    top: 10px;

    @include vp--1279 {
      width: 100px;
    }

    @include vp--1023 {
      transform: rotate(90deg);
      top: calc(100% + 20px);
      width: 50px;
      left: 130px;
      right: auto;
      height: 20px;
      background-size: auto 20px;
    }

    @include vp--767 {
      left: 100px;
    }
  }
}

.contacts__list {
  width: 45%;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  gap: 30px 20px;
  padding-top: 15px;

  @include vp--1023 {
    width: 100%;
    max-width: 450px;
  }

  @include vp--767 {
    grid-template-columns: 100%;
  }
}

.contacts__item-title {
  margin: 0 0 30px;

  font-size: 24px;
  line-height: 1;
  color: $color-blue-dark;

  @include vp--1279 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  @include vp--1023 {
    margin-bottom: 15px;
  }

  @include vp--767 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.contacts__item-link {
  font-size: 28px;
  line-height: 1;
  font-weight: 500;
  color: $color-almost-black;

  transition: color $trans--300;
  outline: none;
  white-space: nowrap;

  @include no-touch {
    &:hover,
    &:focus {
      color: $color-blue;
    }
  }

  @include vp--1439 {
    font-size: 22px;
  }

  @include vp--767 {
    font-size: 20px;
  }
}

