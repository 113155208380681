.intro {
  background: url("../img/background/intro-bg.png") no-repeat;
  background-size: cover;

  position: relative;
  z-index: 1;
  overflow: hidden;
}

.intro__container {
  position: relative;
  padding-top: 215px;
  padding-bottom: 100px;
  min-height: 760px;
  height: calc(100 * var(--vh, 1vh));
  max-height: 800px;

  @include vp--767 {
    padding-top: 110px;
    padding-bottom: 270px;

    height: auto;
    min-height: auto;
    max-height: none;
  }
}

.intro__title {
  margin: 0 0 30px;
  max-width: 900px;

  @media (min-width: 1280px) {
    font-size: 60px;
  }

  @include vp--1279 {
    max-width: 800px;
  }

  @include vp--1023 {
    max-width: 600px;
  }
}

.intro__text {
  max-width: 700px;
  margin-bottom: 95px;

  @media (min-width: 1280px) {
    font-size: 22px;
  }

  p {
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include vp--1279 {
    max-width: 700px;
  }

  @include vp--1023 {
    max-width: 600px;
  }

  @include vp--767 {
    margin-bottom: 50px;
  }
}

.intro__actions {
  display: flex;
  align-items: center;

  @include vp--767 {
    display: block;
  }
}

.intro__btn {
  margin-right: 13px;

  &:last-child {
    margin-right: 0;
  }

  @include vp--767 {
    margin-right: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.intro__image {
  position: absolute;
  z-index: -1;
  right: -270px;
  bottom: -325px;
  width: 768px;
  height: 768px;
  animation: zoom 5s infinite;

  img {
    width: 100%;
    height: 100%;
  }

  @include vp--1023 {
    width: 600px;
    height: 600px;

    right: -170px;
    bottom: -270px;
  }

  @include vp--767 {
    width: 450px;
    height: 450px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    bottom: -220px;
    animation-name: zoomMobile;;
  }
}
