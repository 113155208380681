@keyframes logo {
  0% {
    background-position: 0% 100%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 100%;
  }
}

@keyframes linesRight {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
  }
}

@mixin fadeIn {
  opacity: 0;
  transition: opacity $trans--600;

  .sal-animate & {
    opacity: 1;
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoomMobile {
  0% {
    transform: translateX(-50%) scale(1);
  }

  50% {
    transform: translateX(-50%) scale(1.05);
  }

  100% {
    transform: translateX(-50%) scale(1);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotate {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }

  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}


@keyframes rotateMobile {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
