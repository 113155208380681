.container {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;

  @include vp--1023 {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include vp--767 {
    padding-left: 20px;
    padding-right: 20px;
  }

  &--no-padding {
    @include vp--767 {
      padding: 0;
    }
  }
}
