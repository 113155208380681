.button {
  border: none;
  cursor: pointer;

  display: inline-flex;
  vertical-align: top;
  justify-content: center;
  align-items: center;

  background: $color-blue-dark;
  color: $color-almost-white;

  font-size: 16px;
  line-height: 1;
  font-weight: 600;

  padding: 26px 36px;

  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  transition: $trans--300;
  transition-property: background-color, color;

  text-align: center;

  @include no-touch {
    &:hover,
    &:focus {
      background-color: $color-almost-black;
      color: $color-almost-white;
    }
  }

  &--transparent {
    background: $color-transparent;
    color: $color-almost-black;

    @include no-touch {
      &:hover,
      &:focus {
        background-color: $color-blue-dark;
        color: $color-almost-white;
      }
    }
  }

  &--big {
    width: 100%;
    font-size: 22px;

    @include vp--1279 {
      font-size: 18px;
    }

    @include vp--767 {
      font-size: 16px;
    }
  }

  &--blue-dark {
    background: $color-blue-dark;

    @include no-touch {
      &:hover,
      &:focus {
        background-color: $color-almost-black;
      }
    }
  }

  svg {
    margin-left: 15px;
  }

  @include vp--767 {
    padding: 20px 30px;
  }
}
