.features {
  padding-top: 40px;
  padding-bottom: 80px;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2243px;
    height: 1656px;
    background: url("../img/background/features-bg.svg") no-repeat;
    background-size: cover;
    pointer-events: none;
  }

  @include vp--1023 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.features__container {
  max-width: 1400px;
}

.features__title {
  margin: 0 auto 60px;
  text-align: center;

  @include vp--1023 {
    margin-bottom: 50px;
  }
}

.features__content {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%), rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  border-radius: 40px;
  border: 4px solid $color-default-white;

  padding: 65px 85px 80px;

  @include vp--1279 {
    padding: 40px;
  }

  @include vp--767 {
    padding: 24px;
  }
}

.features__list {
  padding: 0;
  margin: 0;
  list-style: none;

  display: flex;
  flex-wrap: wrap;

  counter-reset: features-list;
}

.features__item {
  width: 50%;
  counter-increment: features-list;

  padding: 60px 118px 70px;
  padding-left: 0;

  border-right: 2px solid #CBDEFF;
  border-bottom: 2px solid #CBDEFF;
  position: relative;

  &:nth-child(2n) {
    padding-right: 0;
    padding-left: 118px;
    border-right: none;
    border-bottom-color: transparent;

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      height: 2px;
      background: linear-gradient(to right, #CBDEFF 0%, #CBDEFF 50%, #CBDEFF 50%, transparent);
    }
  }

  &:last-child {
    margin-bottom: 0;

    &::after {
      display: none;
    }
  }

  &:last-child,
  &:nth-last-child(2) {
    border-bottom: none;
  }

  &:nth-last-child(2):not(:nth-child(2n)) {
    border-right-color: transparent;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      width: 2px;
      height: auto;
      left: auto;
      right: -2px;
      background: linear-gradient(to bottom, #CBDEFF 0%, #CBDEFF 50%, #CBDEFF 50%, transparent);
    }
  }

  &::before {
    content: counter(features-list, decimal-leading-zero);
    display: block;
    font-size: 22px;
    line-height: 1.4;
    color: $color-blue;
    margin-bottom: 10px;
  }

  &:first-child,
  &:nth-child(2) {
    padding-top: 0;
  }

  &:last-child,
  &:nth-last-child(2) {
    padding-bottom: 0;
  }

  @include vp--1279 {
    padding-right: 70px;

    &:nth-child(2n) {
      padding-left: 70px;
    }
  }

  @include vp--1023 {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;

    &:nth-child(2n) {
      padding-left: 30px;
    }
  }

  @include vp--767 {
    width: 100%;

    border-right: none;
    padding-right: 0;

    &::after {
      display: none;
    }

    &:nth-child(2n) {
      padding-left: 0;
    }

    &:nth-child(n) {
      padding-top: 30px;
      padding-bottom: 30px;
      border-bottom: 2px solid #CBDEFF;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.features__item-list {
  .markered-list__title {
    margin-bottom: 25px;
    color: $color-blue;
  }

  .markered-list__item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
