.accordion__item {
  background: $color-default-white;
  box-shadow: 0px 5px 40px rgba(18, 0, 27, 0.07);
  border-radius: 12px;

  padding: 30px;

  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  @include vp--1023 {
    padding: 20px;
  }

  @include vp--767 {
    padding: 16px;

    margin-bottom: 15px;
  }
}

.accordion__item-title {
  margin: 0;
  @include text-medium;

  color: $color-almost-black;
  cursor: pointer;

  display: block;
  width: 100%;
  position: relative;
  padding-right: 200px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;

    width: 20px;
    height: 2px;
    background: $color-almost-black;
    border-radius: 4px;
    top: 15px;
    right: 0;
  }

  &::after {
    transform: rotate(90deg);
    transition: transform $trans--300;

    .is-active & {
      transform: rotate(0deg);
    }
  }

  @include vp--1023 {
    padding-right: 100px;
  }

  @include vp--767 {
    padding-right: 40px;

    &::before,
    &::after {
      width: 15px;
      height: 1px;
      top: 10px;
    }
  }
}

.accordion__item-text {
  padding-right: 40px;

  p {
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include vp--767 {
    padding-right: 0;
  }
}

.accordion__item-text-inner {
  padding-top: 15px;
}
