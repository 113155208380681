.field-text {
  position: relative;
  z-index: 1;
  padding-top: 8px;
}

.field-text__input {
  display: block;
  width: 100%;

  background: $color-almost-white;
  border: 1px solid $color-gray;
  padding: 23px 25px;

  font-size: 22px;
  line-height: 22px;

  border-radius: 6px;
  outline: none;

  &:focus {
    border-color: $color-almost-black;
  }

  .form-field--error & {
    border-color: red;
  }

  @include vp--1279 {
    font-size: 18px;
    line-height: 18px;

    padding: 20px;
  }

  @include vp--1023 {
    font-size: 16px;
    line-height: 16px;

    padding: 15px;
  }

  @include vp--767 {
    font-size: 14px;
    line-height: 14px;

    padding: 12px 15px;
  }
}

.field-text__label {
  position: absolute;
  z-index: 1;
  left: 23px;
  top: 0;
  color: rgba($color-almost-black, 0.4);

  font-size: 18px;
  line-height: 1;
  background: $color-almost-white;
  padding: 0 4px;

  @include vp--1279 {
    left: 18px;

    font-size: 16px;
  }

  @include vp--1023 {
    font-size: 14px;
    left: 12px;
  }

  @include vp--767 {
    font-size: 12px;
  }
}
