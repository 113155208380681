.partners {
  padding-top: 20px;
  padding-bottom: 80px;

  @include vp--1023 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.partners__container {
  max-width: 1440px;
}

.partners__title {
  text-align: center;
  margin: 0 0 100px;

  @include vp--1023 {
    margin-bottom: 55px;
  }
}

.partners__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  max-width: 1225px;
  margin: 0 auto;
}

.partners__item {
  img {
    width: 100%;
    height: auto;
  }

  margin-bottom: 53px;

  &--mastercloud {
    width: percentage(213 / 1225);
  }

  &--pervaya-forma {
    margin-left: percentage(92 / 1225);
    width: percentage(152 / 1225);
  }

  &--oblokoteka {
    margin-left: percentage(92 / 1225);
    width: percentage(228 / 1225);
  }

  &--b24-enterprice {
    margin-left: percentage(92 / 1225);
    width: percentage(355 / 1225);
  }

  &--agora {
    width: percentage(139 / 1225);
    margin-left: percentage(160 / 1225);
    margin-bottom: 40px;
  }

  &--perfvyi-bit {
    margin-left: percentage(75 / 1225);
    width: percentage(178 / 1225);
    margin-bottom: 40px;
  }

  &--kontur {
    margin-left: percentage(80 / 1225);
    width: percentage(122 / 1225);
    margin-bottom: 40px;
  }

  &--golfstrim {
    margin-left: percentage(110 / 1225);
    width: percentage(204 / 1225);
    margin-bottom: 40px;
  }

  &--b24 {
    margin-left: percentage(110 / 1225);
    width: percentage(204 / 1225);
  }

  &--manzana {
    margin-left: percentage(78 / 1225);
    width: percentage(162 / 1225);
  }

  &--moi-ofis {
    margin-left: percentage(70 / 1225);
    width: percentage(207 / 1225);
  }

  &--r7-ofis {
    width: percentage(207 / 1225);
    margin-left: percentage(75 / 1225);
    margin-right: percentage(110 / 1225);
  }

  &--garant {
    margin-left: percentage(110 / 1225);
    width: percentage(184 / 1225);

  }

  &--mindbox {
    margin-left: percentage(64 / 1225);
    width: percentage(159 / 1225);

    @include vp--1023 {
      margin-left: percentage(40 / 1225);
    }
  }

  &--usergate {
    margin-left: percentage(55 / 1225);
    width: percentage(191 / 1225);

    @include vp--1023 {
      margin-left: percentage(40 / 1225);
    }
  }

  &--searchinform {
    margin-left: percentage(55 / 1225);
    width: percentage(243 / 1225);

    @include vp--1023 {
      margin-left: percentage(30 / 1225);
    }
  }

  &--sap {
    margin-left: percentage(325 / 1225);
    width: percentage(81 / 1225);
    margin-bottom: 0;
  }

  &--aws {
    margin-left: percentage(50 / 1225);
    width: percentage(67 / 1225);
    margin-bottom: 0;
  }

  &--ms-azure {
    margin-left: percentage(55 / 1225);
    width: percentage(122 / 1225);
    margin-bottom: 0;
  }

  &--more {
    margin-left: percentage(42 / 1225);
    width: percentage(195 / 1225);
    text-align: center;

    font-size: 24px;
    line-height: 1;
    font-weight: 500;

    color: $color-blue-dark;
    margin-bottom: 0;

    @include vp--767 {
      font-size: 16px;
      margin-left: 0;
      margin-top: 0;
      text-align: left;
    }
  }

  @include vp--767 {
    width: calc(50% - 15px);

    margin-left: 0;
    margin-right: 30px;
    margin-bottom: 30px;
    margin-top: 0;

    &:nth-child(2n) {
      margin-right: 0;
    }

    img {
      width: auto;
      height: auto;
    }
  }
}
