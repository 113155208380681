.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* stylelint-disable-next-line */
  min-height: calc(100 * var(--vh, 1vh));
  overflow: hidden;

  &--no-scrollbar {
    height: 100vh;
    /* stylelint-disable-next-line */
    height: calc(100 * var(--vh, 1vh));

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
  }
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;

  clip: rect(0 0 0 0);
}

.no-transition {
  /* stylelint-disable-next-line */
  transition: none !important;
}

.rouble {
  font-family: $font-family--rouble;
  /* stylelint-disable */
  text-transform: lowercase !important;
  font-weight: 400 !important;
  /* stylelint-enable */
}

.scroll-lock-ios {
  position: fixed;

  overflow: hidden;
}

.scroll-lock {
  overflow: hidden;
}

[data-accordion="element"].is-active > [data-accordion="content"] {
  max-height: 100%;
}

[data-accordion="content"] {
  max-height: 0;
  transition: max-height $trans--300;
  overflow: hidden;
}

.btn-reset {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  text-align: left;

  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

// используйте для скрытия нежелательных анимаций-скачков при поворотах экрана
@keyframes disableBreakpointAnimation {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

.form-field {
  position: relative;
  z-index: 1;
}

.form-field__error {
  font-size: 12px;
  color: red;
  margin-top: 5px;
}
