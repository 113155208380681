.scheme {
  position: relative;
  z-index: 1;
  padding-top: 80px;
  padding-bottom: 80px;

  @include vp--1023 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2224px;
    height: 1304px;
    background: url("../img/background/scheme-bg.svg") no-repeat;
    background-size: cover;
    pointer-events: none;

    @include vp--1023 {
      width: 1500px;
      height: 900px;
    }

    @include vp--767 {
      width: 775px;
      height: 1278px;
      background-image: url("../img/background/scheme-bg-mobile.svg");
    }
  }
}

.scheme__container {
  max-width: 1400px;
}

.scheme__content {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%), rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  border-radius: 40px;
  border: 4px solid $color-default-white;
  padding: 0;
}

.scheme__top {
  padding: 70px 85px;

  @include vp--1279 {
    padding: 51px 31px;
  }

  @include vp--767 {
    padding: 15px 12px 40px;
  }
}

.scheme__title {
  line-height: 1;
  margin: 0 0 60px;
  text-align: center;

  @include vp--767 {
    margin-bottom: 30px;
    font-size: 36px;
    line-height: 1;
  }
}

.scheme__items {
  display: grid;
  grid-template-columns: 23% 31.5% 23%;
  gap: 50px 11.25%;

  @include vp--767 {
    grid-template-columns: repeat(2, calc(50% - 5px));
    gap: 30px 10px;
  }
}

.scheme__item {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  &--left {
    grid-row: span 2;
    grid-column: 1;
    align-items: flex-start;
    padding-top: 10px;

    @include vp--767 {
      grid-row: auto;
      padding-top: 0;
    }
  }

  &--big {
    grid-row: 1;
    grid-column: 2;

    @include vp--767 {
      grid-column: 1 / -1;
      justify-self: center;

      width: 70%;
      max-width: 215px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: -1;

      width: 36%;
      height: 2px;

      background: linear-gradient(to right, #CBDEFF 0%, #CBDEFF 50%, #CBDEFF 50%, $color-transparent);

      top: 50%;
      transform: translateY(-50%);
      right: 100%;

      transition-delay: 0.3s;

      @include vp--767 {
        width: 23px;
        height: 95px;
        transform: none;
        background: url("../img/svg/scheme-arrow-left-mobile.svg");
        background-size: 23px 95px;
      }
    }

    &::after {
      background: linear-gradient(to left, #CBDEFF 0%, #CBDEFF 50%, #CBDEFF 50%, $color-transparent);
      right: auto;
      left: 100%;

      @include vp--767 {
        background: url("../img/svg/scheme-arrow-right-mobile.svg");
      }
    }

    .scheme__item-inner {
      width: 100%;
      min-height: 175px;

      @include vp--1023 {
        min-height: 150px;
      }

      @include vp--767 {
        min-height: auto;
        padding: 33px 30px;
        font-size: 16px;
      }
    }
  }

  &--right {
    grid-row: span 2;
    grid-column: 3;

    align-items: flex-start;
    padding-top: 10px;

    @include vp--767 {
      grid-row: auto;
      grid-column: 2;
      padding-top: 0;
    }
  }

  &--small {
    grid-row: 2;
    grid-column: 2;

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 2;

      width: 50px;
      height: 50px;

      background: #EEF3FC url("../img/svg/protection.svg") no-repeat;
      background-size: 100% 100%;

      bottom: 25px;
      right: calc(100% + 85px);

      @include vp--1023 {
        width: 40px;
        height: 40px;
        right: calc(100% + 70px);
        bottom: 30px;
      }

      @include vp--767 {
        width: 32px;
        height: 32px;
        right: calc(100% + 20px);
        bottom: 42px;
      }
    }

    &::after {
      right: auto;
      left: calc(100% + 90px);

      @include vp--767 {
        left: calc(100% + 20px);
      }
    }

    @include vp--1023 {

      &::before {
        right: calc(100% + 70px);
      }

      &::after {
        left: calc(100% + 70px);
      }
    }


    @include vp--374 {
      &::before {
        right: calc(100% + 10px);
      }

      &::after {
        left: calc(100% + 10px);
      }
    }


    @include vp--767 {
      grid-row: auto;
      grid-column: 1 / -1;
      justify-self: center;
    }
  }

  &--left,
  &--right {
    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      width: 2px;
      height: 30%;
      background: linear-gradient(to top, #CBDEFF 0%, #CBDEFF 50%, #CBDEFF 50%, $color-transparent);
      bottom: 50px;
      left: 50%;

      @include vp--767 {
        bottom: auto;
        top: 100%;
        left: 20px;
        height: 95px;
      }
    }

    &::after {
      height: 2px;
      width: 100%;
      left: 50%;
      background: linear-gradient(to right, #CBDEFF 0%, #CBDEFF 80%, #CBDEFF 80%, $color-transparent);

      @include vp--1023 {
        width: 80%;
      }

      @include vp--767 {
        width: calc(100% - 70px);
        height: 2px;
        top: calc(100% + 95px);
        background: linear-gradient(to right, #CBDEFF 0%, #CBDEFF 95%, #CBDEFF 95%, $color-transparent);
      }

    }
  }

  &--right {
    &::before {
      @include vp--767 {
        left: auto;
        right: 20px;
      }
    }

    &::after {
      left: auto;
      right: 50%;
      background: linear-gradient(to left, #CBDEFF 0%, #CBDEFF 80%, #CBDEFF 80%, $color-transparent);

      @include vp--767 {
        left: auto;
        right: 20px;
        background: linear-gradient(to left, #CBDEFF 0%, #CBDEFF 95%, #CBDEFF 95%, $color-transparent);
      }
    }
  }
}

.scheme__item-inner {
  padding: 30px;
  text-align: center;
  border-radius: 16px;

  font-size: 18px;
  line-height: 1.1;
  font-weight: 500;
  width: 100%;
  min-height: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;

  transition-delay: var(--startDelay);

  @include vp--1279 {
    font-size: 14px;
  }

  @include vp--1023 {
    padding: 24px 15px;
  }

  @include vp--767 {
    font-size: 16px;
    line-height: 1.2;

    padding: 17px;

    min-height: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 40px;
    background: linear-gradient(225deg, #AFCBFF 0%, #ECD9FF 100%);

    @include vp--767 {
      border-radius: 30px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    border-radius: 40px;
    background: #eef3fa;

    @include vp--1279 {
      background: #eff4fb;
    }

    @include vp--767 {
      background: #f7f9fd;
      border-radius: 30px;
    }
  }

  .scheme__item--big & {
    padding: 70px 40px;
    border-radius: 80px;

    &::before {
      border-radius: 80px;
      background: linear-gradient(225deg, rgba(225, 217, 255, 0.2) 23.45%, rgba(225, 217, 255, 0.7) 37.85%, rgba(182, 205, 255, 0.7) 61.39%, rgba(182, 205, 255, 0.2) 74.66%);
    }

    &::after {
      border-radius: 80px;
      left: 40px;
      right: 40px;
      top: 40px;
      bottom: 40px;
    }

    @include vp--767 {
      &::after {
        left: 20px;
        right: 20px;
        top: 20px;
        bottom: 20px;
      }
    }
  }

  .scheme__item--small & {
    padding: 24px 70px;
    min-height: auto;
    width: auto;
    flex-direction: row;

    .scheme__icon {
      width: 64px;
      height: 64px;
      margin-bottom: 0;
      margin-right: 20px;
    }

    &::after {
      background: #e7eefa;

      @include vp--1023 {
        background: #edf2fb;
      }

      @include vp--767 {
        background: #f0f4fb;
      }
    }

    @include vp--767 {
      padding: 15px;
      padding-bottom: 25px;
      flex-direction: column;

      .scheme__icon {
        margin-right: 0;
      }
    }
  }

  .scheme__item--right & {
    &::before {
      background: linear-gradient(90deg, #3DDEED 0%, #CB00DD 100%);
    }
  }

  .scheme__item--left & {

    &::after {
      @include vp--1279 {
        background: #f1f5fc;
      }

      @include vp--1023 {
        background: #f6f8fc;
      }
    }
  }
}

.scheme__icon {
  margin-bottom: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.scheme__image {
  max-width: 80%;
  margin: 15px auto 0;

  @include vp--767 {
    max-width: 95%;
  }
}

.scheme__bottom {
  padding: 77px 81px;

  display: flex;
  align-items: flex-start;
  position: relative;
  transition: none;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 15px;
    right: 15px;
    height: 2px;
    background: url("../img/svg/dot-pattern.svg") repeat-x;

    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }

  &.sal-animate::before  {
    animation: linesRight 1s forwards;
  }

  @include vp--1279 {
    padding: 51px 31px;
    align-items: flex-start;
  }

  @include vp--767 {
    padding: 30px 15px 20px;
    padding-right: 10px;
    flex-direction: column;
  }
}

.scheme__no-internet {
  display: flex;
  align-items: center;

  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
  max-width: 590px;

  svg {
    display: block;
    width: 71px;
    height: 70px;
    flex-shrink: 0;
    margin-right: 30px;
  }

  @include vp--1279 {
    font-size: 18px;
    max-width: 490px;
    margin-right: 20px;

    svg {
      width: 64px;
      height: 64px;
      margin-right: 20px;
    }
  }

  @include vp--1023 {
    font-size: 14px;
    max-width: 450px;
    align-items: flex-start;

    svg {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
  }

  @include vp--767 {
    font-size: 16px;
    margin-bottom: 27px;
    margin-right: 0;

    svg {
      margin-right: 15px;
      width: 71px;
      height: 70px;
    }
  }
}

.scheme__list {
  width: 50%;
  max-width: 565px;
  margin-left: auto;

  .markered-list__items {
    gap: 15px 40px;
  }

  @include vp--1279 {
    max-width: 330px;
    padding-top: 10px;
  }

  @include vp--767 {
    padding-top: 0;
    padding-left: 2px;
    margin-left: 0;

    font-size: 18px;
    line-height: 1.4;

    .markered-list__items {
      gap: 15px;
    }

    .markered-list__item {
      padding-left: 15px;

      &::before {
        top: 8px;
      }
    }
  }
}
