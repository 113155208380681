.markered-list {
  @include vp--767 {
    line-height: 20px;
  }
}

.markered-list__title {
  margin: 0 0 48px;
}

.markered-list__items {
  padding: 0;
  margin: 0;
  list-style: none;
}

.markered-list__item {
  position: relative;
  z-index: 1;

  padding-left: 16px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $color-blue;
    top: 8px;
    left: 0;
    opacity: 0;
    transition: opacity $trans--600;
    transition-delay: var(--itemStartDelay);

    .sal-animate & {
      opacity: 1;
    }
  }

  @include vp--767 {
    padding-left: 11px;

    &::before {
      top: 5px;
    }
  }
}

.markered-list--two-cols {
  .markered-list__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  .markered-list__item  {
    margin-bottom: 0;
  }

  @include vp--1023 {
    .markered-list__items {
      gap: 20px;
    }
  }

  @include vp--767 {
    .markered-list__items {
      grid-template-columns: 100%;
      gap: 10px;
    }
  }
}

.markered-list--arrow {
  display: flex;
  align-items: flex-start;
  position: relative;

  .markered-list__title {
    width: 286px;
    margin-right: auto;
    flex-shrink: 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      width: calc(100% - 750px);
      max-width: 368px;
      height: 30px;
      background: url("../img/svg/dots.svg") no-repeat;
      background-size: 368px 30px;
      background-position: right center;
      left: 300px;
      top: 5px;
    }

    &::after {
      background-image: url("../img/svg/arrow.svg");
      transform: translateX(calc(-100% + 50px));
      transition: transform 1s;
      transition-delay: 0.2s;

      .sal-animate & {
        transform: translateX(0);
      }
    }
  }

  &:nth-child(2) {
    .markered-list__title::after {
      transition-delay: 1.2s;
    }
  }

  .markered-list__items {
    flex-grow: 1;
    max-width: 465px;
    padding-top: 5px;
  }

  .markered-list__item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }


  @include vp--1279 {
    .markered-list__title {
      width: 240px;

      &::before,
      &::after {
        left: 255px;
      }
    }
  }

  @include vp--1023 {
    .markered-list__title {
      &::before,
      &::after {
        width: calc(100% - 600px);
      }
    }

    .markered-list__items {
      max-width: 320px;
    }
  }

  @include vp--767 {
    flex-direction: column;

    .markered-list__title {
      margin-bottom: 60px;
      width: 100%;
      text-align: center;
      position: relative;

      &::before,
      &::after {
        width: 50px;
        height: 20px;
        background-size: auto 20px;
        transform: translateX(-50%) rotate(90deg);
        left: 50%;
        top: calc(100% + 15px);
      }

      &::after {
        .sal-animate & {
          transform: translateX(-50%) rotate(90deg);
        }
      }
    }

    .markered-list__items {
      padding-top: 0;
      max-width: 100%;
    }
  }
}
