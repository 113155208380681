.field-checkbox__label {
  display: block;
  position: relative;
  padding-left: 39px;

  font-size: 18px;
  line-height: 24px;
  cursor: pointer;

  color: $color-blue-dark;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 24px;
    height: 24px;
    border: 1px solid $color-blue-dark;
    left: 0;
    top: 0;

    .form-field--error & {
      border-color: red;
    }
  }

  &::after {
    border: none;
    background: $color-blue-dark url("../img/svg/check.svg") no-repeat center;
    opacity: 0;
    transition: opacity $trans--300;
  }

  input:focus + & {
    &::after {
      background-color: $color-almost-black;
    }
  }

  input:checked + & {
    &::after {
      opacity: 1;
    }
  }

  @include vp--1279 {
    font-size: 16px;
  }

  @include vp--767 {
    font-size: 14px;
  }
}
