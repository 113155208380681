@mixin h1 {
  font-size: 64px;
  line-height: 1.2;
  font-weight: 500;
  color: $color-blue-dark;

  @include vp--1279 {
    font-size: 48px;
  }

  @include vp--1023 {
    font-size: 36px;
  }

  @include vp--767 {
    font-size: 32px;
  }
}

@mixin h2 {
  font-size: 44px;
  line-height: 1.4;
  font-weight: 500;
  color: $color-blue-dark;

  @include vp--1279 {
    font-size: 40px;
  }

  @include vp--1023 {
    font-size: 32px;
  }

  @include vp--767 {
    font-size: 26px;
    line-height: 1.2;
  }
}

@mixin h3 {
  font-size: 30px;
  line-height: 1.4;
  font-weight: 500;
  color: $color-blue-dark;

  @include vp--1279 {
    font-size: 24px;
  }

  @include vp--1023 {
    font-size: 20px;
  }

  @include vp--767 {
    font-size: 18px;
  }
}

@mixin text-medium {
  font-size: 22px;
  line-height: 1.4;
  font-weight: 400;

  @include vp--1023 {
    font-size: 18px;
  }

  @include vp--767 {
    font-size: 16px;
  }
}

@mixin text-24 {
  font-size: 24px;
  line-height: 1.4;

  @include vp--1279 {
    font-size: 20px;
  }

  @include vp--1023 {
    font-size: 18px;
  }

  @include vp--767 {
    font-size: 16px;
  }
}

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}

.text-24 {
  @include text-24;
}
