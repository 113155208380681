.footer {
  background: url("../img/background/footer-bg.jpg") no-repeat;
  background-size: cover;

  padding: 70px 30px;

  @include vp--1023 {
    padding: 40px 24px;
  }

  @include vp--767 {
    padding: 40px 16px;
  }
}

.footer__content {
  margin-top: 70px;

  display: flex;
  align-items: baseline;
  justify-content: space-between;

  a {
    color: inherit;

    transition: color $trans--300;
    outline: none;

    @include no-touch {
      &:hover,
      &:focus {
        color: $color-blue;
      }
    }
  }

  @include vp--1023 {
    flex-direction: column;
    align-items: flex-start;
  }

  @include vp--767 {
    margin-top: 40px;
  }
}

.footer__copyright {
  margin: 0;

  @include vp--1023 {
    margin-bottom: 20px;
  }
}

.footer__privacy {
  text-decoration: underline;


  @include vp--1023 {
    margin-bottom: 20px;
  }
}

.footer__dev {
  margin: 0;

  display: flex;
  align-items: center;

  img {
    width: 44px;
    height: 44px;
    margin-left: 8px;
  }
}
