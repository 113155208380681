.header {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;

  opacity: 0;
  transform: translateY(-50px);
  animation: slideDown 1s forwards;

  span {
    display: block;
    text-align: right;

    font-size: 14px;
    line-height: 20px;

    @include vp--767 {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.header__wrapper {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header__logo {
  display: block;
  padding: 10px 0;

  img {
    width: 237px;
    height: 30px;
  }

  @include vp--767 {
    img {
      width: 110px;
      height: 16px;
    }
  }
}

.header__phone {
  margin-left: auto;

  font-weight: 600;

  display: flex;
  align-items: center;
  padding: 10px 0;

  svg {
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 15px;
  }
}
